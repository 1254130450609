<script>
	import { Bar } from 'vue-chartjs'

	export default {
		name: "bar-chart",
		extends: Bar,
		props: {
			chartData: {
				type: Object,
				required: true
			},
			chartOptions: {
				type: Object,
				required: true
			},
		},
		mounted () {
			this.renderChart(this.chartData, this.chartOptions)
		}
	}
</script>