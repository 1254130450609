<template>
  <div>
    <h2>{{ activeLanguage.title }}</h2>
    <table class="table">
      <thead>
      <tr>
        <th class="text-left">
          {{ activeLanguage.number }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th>
          {{ activeLanguage.date }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th class="amount-title">
          {{ activeLanguage.amount }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th></th>
      </tr>
      </thead>
      <tfoot>
      <tr>
        <td colspan="4">
          <router-link to="/invoices" class="btn btn-secondary">
            {{ activeLanguage.viewAllInvoicesBtn }}
          </router-link>
        </td>
      </tr>
      </tfoot>
      <tbody>
      <template v-for="inv in invoices">
        <tr>
          <td>#{{ inv.number }}</td>
          <td>{{ inv.date }}</td>
          <td class="amount"><span>€</span> {{ formattedAmount(inv.amount) }}</td>
          <td class="text-center">
            <a @click.prevent="showDownloadRow(inv)" href="#" class="download-link" data-toggle="collapse"><i
                class="fa fa-download" aria-hidden="true"></i></a>
          </td>
        </tr>
        <tr class="table-res" v-if="inv.showDownload">
          <td colspan="4" class="download-row">
            <div class="download-collapse collapse show" style="padding: 0 20px;">
              <div class="form-row pt-2">
                <div class="form-group col-md-7">
                  <select
                      class="form-control"
                      style="height: 34px"
                      @change="fileTypeSelected($event, inv)"
                  >
                    <template v-if="inv.username === `Paynl`">
                      <option v-for="item in activeLanguage.selectOptions" :value="item">{{ item }}</option>
                    </template>
                    <template v-if="inv.username !== `Paynl`">
                      <option v-for="item in activeLanguage.selectOptions" :value="item" v-if="!item.includes('PayNL')">
                        {{ item }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="form-group col-md-5">
                  <button @click="downloadInv(inv)" class="btn btn-secondary mr-sm-2">{{ activeLanguage.downloadBtn }}
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "client-invoices",
  props: {
    activeLanguage: {
      type: Object,
      required: true
    },
    invoices: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      types: [
        {
          type: 'main',
          ext: 'pdf',
          textEn: 'Invoice (PDF)'
        },
        {
          type: 'match-entry-bundled',
          ext: '',
          textEn: "Match entries - bundled costs",
          textNl: 'Match entries - gebundelde kosten'
        },
        {
          type: 'match-entry-individual',
          ext: '',
          textEn: "Match entries - individual costs",
          textNl: 'Match entries - individuele kosten',
        },
        {
          type: 'mt940',
          ext: '',
          textEn: 'MT940'
        },
        {
          type: 'specs',
          ext: 'pdf',
          textEn: 'Costs specification (PDF)',
          textNl: "Kosten specificatie (PDF)"
        },
        {
          type: 'specs',
          ext: 'xlsx',
          textEn: 'Costs specification (CSV)',
          textNl: "Kosten specificatie (CSV)"
        },
        {
          type: 'pay-specs',
          ext: 'pdf',
          textEn: 'Payment specification (PDF)',
          textNl: "Betalingsspecificatie (PDF)"
        },
        {
          type: 'pay-specs',
          ext: 'xlsx',
          textEn: 'Payment specification (CSV)',
          textNl: "Betalingsspecificatie (CSV)"
        },
        {
          type: 'paynl_specs',
          ext: 'xlsx',
          textEn: 'PayNL specification (CSV)',
          textNl: "PayNL specificatie (CSV)"
        }
      ]
    }
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
  methods: {
    showDownloadRow(inv) {
      inv.showDownload = !inv.showDownload
      this.$forceUpdate()
    },
    fileTypeSelected(e, inv) {
      let item = ''
      if (e.target.value !== 'Choose specification' || e.target.value !== 'Kies specificatie') {
        item = this.types.find((item) => item.textEn === e.target.value || item.textNl === e.target.value)
      }

      inv.downloadType = item
    },
    downloadInv({id, downloadType}) {
      let params = {
        file_type: downloadType.type,
        file_ext: downloadType.ext,
        file: id
      }

      billinkAxios
      .get(`app/client-invoice/download/`, {params, responseType: 'blob'})
      .then((res) => {
        let file = res.headers['content-disposition'].slice(0, -1).replace('inline; filename="', '').split('.'),
            link = document.createElement('a');

        link.href = window.URL.createObjectURL(res.data)
        link.setAttribute('download', `${file[0]}.${file[1]}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(err => {
        console.error(err)
        if (err.response.status === 400) {
          EventBus.$emit('showAlertModal', 'Such File doesn\'t exist')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
td:nth-of-type(1), th:nth-of-type(1) {
  padding-left: 20px;
  width: 140px;
}

.download-collapse {
  width: 100%;

  button {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }

  button, select {
    height: 34px !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}

tfoot .btn {
  /*padding-top: 6px;*/
  /*padding-bottom: 6px;*/
  /*font-size: 12px;*/
}

@media (max-width: 1120px) {
  .fa.fa-sort {
    display: none;
  }
}

@media (max-width: 440px) {
  .table {
    td, th {
      font-size: 10px;
      padding: 2px 6px;
      vertical-align: middle;
    }

    th {
      padding: 6px;
    }
  }
  .download-collapse {
    button, select {
      font-size: 10px;
      height: 24px !important;
    }
  }
}
</style>