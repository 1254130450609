<template>
    <table class="table">
                            <thead class="thead-dark">
                            <tr>
                                <th scope="col">#id</th>
                                <th scope="col" class="text-center">Status</th>
                                <th scope="col" width="98px">{{ activeLanguage.groupTable.date }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.reason }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.inv }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.from }}</th>
                                <th scope="col">{{ activeLanguage.groupTable.to }}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="dispute in disputes"
                                v-if="!dispute.hidden"
                                @click="goToDispute(dispute)"
                                style="cursor: pointer;"
                            >
                                <td>#{{ dispute.id }}</td>
                                <td class="text-center status-icon">
                                    <i
                                        class="fa"
                                        :class="dispute.icon"
                                        aria-hidden="true"
                                        v-b-tooltip.hover.right.title="dispute.tip"
                                    ></i>
                                </td>
                                <td>{{ dispute.date }}</td>
                                <td>{{ dispute.lastDisputeReason }}</td>
                                <td><div class="overflow">{{ dispute.invoice }}</div></td>
                                <td><div class="overflow">{{ dispute.customerEmail }}</div></td>
                                <td><div class="overflow">{{ dispute.clientEmail }}</div></td>
                                <td></td>
                            </tr>
                            </tbody>
                            <tfoot v-if="!check" class="tfoot-no-check">
                                <tr>
                                    <td :colspan="manageOrderRight ? 8 : 7">
                                        <router-link to="/dispute/employee/group/show" class="btn btn-secondary mr-sm-2">
                                            {{ activeLanguage.allDisputesBtn }}
                                        </router-link>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
</template>

<script>
	export default {
		name: "dashboard-last-order-table",
		props:{
			activeLanguage: {
				type: Object,
				required: true
			},
			check: {
				type: Boolean,
				default: false
			},
			sorting: {},
			disputes: {
				type: Array,
				required: true
			},
			showKVK: {
				type: Boolean,
				default: false
			},
			manageOrderRight: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				checkAll: false
			}
        },
		watch: {
			checkAll: function (val) {
				let ids = []

				this.orders.forEach(order => {
					order.checked = val
					if (val) { ids.push(order.id) }
				})

				this.$emit('orderIsSelected', ids)
				this.$forceUpdate()
			},
			orders: function (orders) {
				orders.forEach((order) => {
					switch (order.icon) {
						case 'Betaald':
							order.class = 'fa-check-circle'
							break;
						case 'Nog niet gestart':
							order.class = 'fa-circle'
							break;
						case 'Incasso':
							order.class = 'fa-exclamation-circle'
							break;
						case 'Gepauzeerd':
							order.class = 'fa-pause-circle'
							break;
						case 'Gestart':
							order.class = 'fa-play-circle'
							break;
						case 'Gecrediteerd':
							order.class = 'fa-circle fa-stack-2x'
							break;
					}
				})
			}
		},
		methods: {
			orderIsSelected({checked, id}) {
				let ids = []

				this.orders.forEach(order => {
					if (order.checked) {
						ids.push(order.id)
					}
				})

				this.$emit('orderIsSelected', ids)
			},
			onOrderClick(e, id, isWheel = false) {
				if (isWheel || e.altKey || e.ctrlKey) {
					window.open(`/order/${id}`, '_blank');
				} else {
					this.$router.push(`/order/${id}`)
				}
			},
			onSortChange(type) {
				if (this.check) { this.$emit('onSortChange', type) }
			},
			setSortIconClass(type) {
				let className = 'fa fa-sort'
				if (this.sorting.sort === type) {
					className = this.sorting.sort_dir === 'desc' ? 'fa fa-sort-desc' : 'fa fa-sort-asc'
				}
				return className
            },
      goToDispute(dispute) {
        let id = dispute.id || dispute.groupId

        disputeAxios
            .get(`/api/employee-dispute/group-view/${id}`)
            .then(({ data }) => {
              if (data.dispute.length > 1) {
                window.open(`/order/${dispute.invoice}?openDispute=true&id=${dispute.id}`, '_blank');
              } else if (data.dispute.length === 1) {
                window.open(`/order/${dispute.invoice}?openDispute=true&id=${dispute.id}`, '_blank');
              }
            })
            .catch(err => console.error(err))
      },
		}
	}
</script>

<style scoped lang="scss">
    .fa-stack-2x {
        border-radius: 50%;
        background: #c1a1c7;
    }
    .webshop-th {
        width: 100px;
    }
    .overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        &-inv {
            width: 160px;
        }
        &-webshop {
            width: 120px;
        }
    }
    .name-td {
        div {
            width: 160px;
        }
    }
    @media (max-width: 1400px) {
        .webshop-th,
        .webshop-td {
            display: none !important;
        }
    }
    @media (max-width: 768px) {
        .table {
            td, th {
                font-size: 10px;
                padding: 2px 6px;
                vertical-align: middle;
            }
            th {
                padding: 6px;
                &.openstaand,
                &.amount {
                    width: 70px;
                }
            }
            .overflow {
                width: 70px;
            }
            .tfoot-no-check td {
                text-align: center;
                &>* {
                    padding: 5px;
                    font-size: 11px;
                    margin: 0 20px;
                }
            }
        }
    }
    @media (max-width:1240px) {
        th i {
            display: none;
        }
        .cl .overflow {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    @media (max-width:480px) {
        .table {
            .cl .overflow {
                width: 80px;
            }
            td:last-child,
            .openstaand {
                border-right: 1px solid #003d5b !important;
            }
        }
    }
</style>