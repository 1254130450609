<template>
  <div>
    <h2>{{ activeLanguage.title }}</h2>
    <!--<img src="/svg/graphic.svg">-->
    <BarChart
        :chartData="chartData"
        :chartOptions="chartOptions"
    />
  </div>
</template>

<script>
import BarChart from '../common/reusable/charts/BarChart'

export default {
  name: "total-order-amount",
  props: {
    activeLanguage: {
      type: Object,
      required: true
    },
    graphData: {
      type: Array,
      required: true
    }
  },
  data() {
    let month = new Date().getMonth(),
        prevMonth = month - 5,
        nextMonth = month + 1

    if (prevMonth < 0) {
      prevMonth = 12 + prevMonth
      nextMonth += 12
    }

    let labels = [
      ...this.activeLanguage.months.monthArr,
      ...this.activeLanguage.months.monthArr
    ].slice(prevMonth, nextMonth)

    return {
      chartData: {
        labels,
        datasets: [
          {
            label: this.activeLanguage.months.name,
            backgroundColor: '#f87979',
            data: this.graphData
          }
        ]
      },
      chartOptions: {
        title: {
          text: [this.activeLanguage.months.name],
          display: true,
          position: 'bottom',
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return '€ ' + Helper.formatAmount(value);
              }
            },
            suggestedMin: '€ ' + Helper.formatAmount(0)
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  components: {
    BarChart
  }
}
</script>

<style scoped>

</style>