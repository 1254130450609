<template>
    <div class="main dashboard">
        <div class="container">
            <div class="title">
                <h1>
                    {{ activeLanguage.title }}
                    <!--<a @click.prevent="toggleHelpSidebar" href="#"><i class="fa fa-question-circle question" aria-hidden="true"></i></a>-->
                </h1>
                <p class="intro">
                    {{ activeLanguage.titleText }}
                </p>
            </div>
            <template v-if="!manageOrderRight">
                <form class="form-inline float-right" @submit.prevent="getOrders()">
                <input
                    class="form-control mr-sm-2"
                    v-model="searchOrderVal"
                    type="text"
                    :placeholder="activeLanguage.lastOrdersTable.searchText"
                >
                <button class="btn nopadding" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
            </form>
            <h2>{{ activeLanguage.lastOrdersTable.title }}</h2>
            <OrdersTable
                :activeLanguage="activeLanguage.lastOrdersTable"
                :orders="orders"
                :manageOrderRight="manageOrderRight"
            />
            </template>
            <h2 v-if="!!manageOrderRight">{{  activeLanguage.lastDisputes.title }}</h2>
            <DisputesTable v-if="!!manageOrderRight" 
                          :disputes="disputes" :manageOrderRight="manageOrderRight"
                          :activeLanguage="disputeLanguage"/>
            <div class="row">
                <div class="col-lg-6" v-if="!manageOrderRight">
                    <TotalOrderAmount
                        v-if="graphData.length && showGraph"
                        :graphData="graphData"
                        :activeLanguage="activeLanguage.totalRavenuTable"
                    />
                </div>
                <div class="col-lg-6" v-if="!manageOrderRight && hiddenPlatforms">
                    <ClientInvoices
                        :invoices="invoices"
                        :activeLanguage="activeLanguage.billinkInvoicesTable"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrdersTable from '@/components/common/reusable/OrdersTable.vue'
import TotalOrderAmount from '@/components/dashboardView/TotalOrderAmount.vue'
import ClientInvoices from '@/components/dashboardView/ClientInvoices.vue'
import DisputesTable from '@/components/dashboardView/DisputesTable.vue'
import {mapGetters} from  "vuex"
import moment from 'moment'

export default {
	name: 'dashboard',
    data() {
		return {
			searchOrderVal: '',
            orders: [],
            disputes: [],
            invoices: [],
			graphData: [],
			showGraph: true,
      disable_platforms: ['pay_nl', 'sisow', 'curopayments', 'buckaroo']
        }
    },
    components: {
	    OrdersTable,
	    TotalOrderAmount,
        ClientInvoices,
        DisputesTable
    },
	computed: {
		...mapGetters({
			activeLanguage: 'getDashboardLanguage',
            manageOrderRight: 'userManage_orders',
            disputeLanguage: 'getDisputeGroupsLanguage',
      activeInvLanguage: 'getInvoiceGenerationLanguage',
      btoken: 'getBillinkToken',
		}),
    hiddenPlatforms () {
      return !this.disable_platforms.includes(this.btoken.details.client_platform);

    }
	},
    watch: {
	    activeLanguage: function() {
	    	this.showGraph = false
	    	setTimeout(() => this.showGraph = true , 0)
        }
    },
	mounted() {
        if (!this.manageOrderRight) this.getOrders()
        this.getDisputes()
        if (!this.manageOrderRight) {
            this.getOrders()
            this.getInvoices()
            this.getGraph()
        }
	},
    methods: {
		getOrders() {
			let params = {
			  filter : {
                  client_id: this.btoken.details.id
              }
            }
            if (!!this.searchOrderVal) {
                params.filter = { invoice: this.searchOrderVal }
            }

			billinkAxios
                .get('app/order-list', { params })
				.then(({ data }) => {
          this.orders = data.order_list.slice(0, 10);
          if (!this.orders.length && !!this.searchOrderVal) {
            EventBus.$emit('showAlertModal', this.activeInvLanguage.popupErr )
          }
        })
				.catch(error => console.error(error))
        },
        getDisputes() {
             disputeAxios.get('/api/employee-dispute/groups?isResolved=false&email=&page=1&perPage=5')
                    .then(({ data }) => {
                        this.formatDisputes(data.items_list)
                    }).catch(err => console.error(err))
        },
        getInvoices() {
	        billinkAxios
                .get('app/client-invoices/list?=pages=1')
		        .then(({ data }) => this.invoices = data.invoice_list.slice(0, 5))
		        .catch(err => console.error(err))
        },
        getGraph() {
	        billinkAxios
                .get('app/graph/show/last-six-months')
		        .then(({ data }) => this.graphData = data.split(','))
		        .catch(err => console.error(err))
        },
		toggleHelpSidebar() {
			this.$store.dispatch('toggleHelpModal')
        },
        renderDisputeIcon({ status }) {
				switch (status) {
                    case 'resolved': return { icon: 'fa fa-check-circle', tip: this.activeLanguage.iconResolved }
                    case 'created': return { icon: 'fa fa-play-circle', tip: this.activeLanguage.iconCreated }
                    case 'no_reaction': return { icon: 'fa fa-clock-o', tip: this.activeLanguage.iconNoReaction }
                    case 'reaction': return { icon: 'fa fa-bell', tip: this.activeLanguage.iconReaction }
                }
        },
        formatDisputes(data) {
	            this.disputes = data.map(
	            	dispute => {
		                let { icon, tip } = this.renderDisputeIcon(dispute)
                        dispute.icon = icon
                        dispute.tip = tip
                        dispute.date = moment(dispute.createdAt.date).format('DD-MM-YYYY')
                        return dispute
                    }
	            )
            },
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width: 480px) {
        h2 {
            width: 100%;
            text-align: center;
        }
        .form-inline.float-right{
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
        }
    }
</style>
